//packages/app/src/components/home/HomePage.tsx
import React from 'react';
import { Grid } from '@material-ui/core';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';


export const HomePage = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">

      <Grid item md={6} xs={12}>
        <HomePageRequestedReviewsCard />
      </Grid>
      <Grid item md={6} xs={12}>
        <HomePageYourOpenPullRequestsCard query="is:open is:pr author:@me archived:false org:netMedi" />
      </Grid>
    </Grid>
  );
};